// Styles SCSS
import "../sass/app.scss";

// Masonry layout
import Masonry from "./utils/masonry";

// Carousel
import "slick-carousel";

// SVG polyfiil
import "svgxuse";

// // Accordions
// import "accordion-js";

//Tabs
import { Tabs } from "./utils/tabs";

// pushbar
import Pushbar from "./utils/pushbar";

// Lightbox
import "./utils/fancybox";

import "simplebar";

//lazy load
import LazyLoad from "vanilla-lazyload";

import datepicker from "js-datepicker";

$(document).ready(() => {
	var Largeur = $(window).width();
	new LazyLoad({
		elements_selector: ".lazy",
	});

	$(".header__search").on("click", function () {
		$(".searchform__input").slideToggle();
		$(".searchform__input").focus();
	});

	$(".filter__right").on("click", ".next", function (e) {
		e.preventDefault();
		console.log($(this));

		var position = $($(this).attr("href")).offset().top;

		$("body, html").animate(
			{
				scrollTop: position,
			} /* speed */
		);
	});

	$(".results-filter .mixte").addClass("active");

	if (Largeur <= 768) {
		$(".results-filter .mixte").removeClass("active").hide();
		$(".results-filter .liste").addClass("active");
		$(".filter").removeClass("layout__mixte");
		$(".filter").removeClass("layout__carte");
		$(".filter").addClass("layout__liste");
	}

	$(".liste").on("click", function () {
		$(".results-filter span").removeClass("active");
		$(this).addClass("active");
		$(".filter").removeClass("layout__mixte");
		$(".filter").removeClass("layout__carte");
		$(".filter").addClass("layout__liste");
	});
	$(".mixte").on("click", function () {
		$(".results-filter span").removeClass("active");
		$(this).addClass("active");
		$(".filter").removeClass("layout__liste");
		$(".filter").removeClass("layout__carte");
		$(".filter").addClass("layout__mixte");
	});
	$(".carte").on("click", function () {
		$(".results-filter span").removeClass("active");
		$(this).addClass("active");
		$(".filter").removeClass("layout__mixte");
		$(".filter").removeClass("layout__liste");
		$(".filter").addClass("layout__carte");
		google.maps.event.trigger(map, "resize");
		center_map(map);
	});

	$(".partenaires-top").on("click", function () {
		$(".partenaires-links").slideToggle();
		$(".partenaires-links").focus();
	});

	// Gtranslate
	jQuery('.translate option:contains("Select Language")').text("langues");
	jQuery('.translate option:contains("English")').text("EN");
	jQuery('.translate option:contains("French")').text("FR");
	jQuery('.translate option:contains("Spanish")').text("ES");
	jQuery('.translate option:contains("FR")').attr("selected", true);

	if (document.querySelector(".accordion-container") !== null) {
		var accordion = new Accordion(".accordion-container");
	}
	if (document.querySelector(".tabs-container") !== null) {
		new Tabs({
			elem: "tabs",
			open: 0,
		});
	}

	new Pushbar({
		blur: true,
		overlay: true,
	});

	$('[data-fancybox="galerie"]').fancybox({
		thumbs: {
			autoStart: true,
		},
	});

	if (document.querySelector(".galerie") !== null) {
		const grid = document.querySelector(".galerie");
		new Masonry(grid, {
			itemSelector: ".galerie__item",
			columnWidth: 280,
			gutter: 10,
		});
	}
	$(".slider-for").slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		adaptiveHeight: true,
		fade: true,
		asNavFor: ".slider-nav",
	});
	$(".slider-nav").slick({
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: ".slider-for",
		dots: false,
		centerMode: true,
		focusOnSelect: true,
	});

	jQuery("footer .location span").on("click", function () {
		jQuery("footer .location span").removeClass("active");
		jQuery(this).toggleClass("active");

		if (jQuery(this).hasClass("paris") && jQuery(this).hasClass("active")) {
			jQuery("footer img.france").removeClass("active");
			jQuery("footer img.paris").addClass("active");
		} else {
			jQuery("footer img.france").addClass("active");
			jQuery("footer img.paris").removeClass("active");
		}
	});

	if (document.querySelector(".date-debut") !== null) {
		datepicker(".date-debut", {
			startDay: 1,
			customMonths: [
				"janvier",
				"février",
				"mars",
				"avril",
				"mai",
				"juin",
				"juillet",
				"août",
				"septembre",
				"octobre",
				"novembre",
				"décembre",
			],
			customDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Vend", "Sam"],
			formatter: (input, date, instance) => {
				const value = date.toLocaleDateString("fr-FR");
				input.value = value; // => '1/1/2099'
			},
		});
		datepicker(".date-fin", {
			startDay: 1,
			customMonths: [
				"janvier",
				"février",
				"mars",
				"avril",
				"mai",
				"juin",
				"juillet",
				"août",
				"septembre",
				"octobre",
				"novembre",
				"décembre",
			],
			customDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Vend", "Sam"],
			formatter: (input, date, instance) => {
				const value = date.toLocaleDateString("fr-FR");
				input.value = value; // => '1/1/2099'
			},
		});
	}

	if (Largeur < 900) {
		$(".logo-pv").appendTo(".adresse");
	}
});
